<template>
    <div class="content-warper">
        
    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped>
.content-warper {
  min-height: 80%;
  max-width: 1300px;
  margin: auto;
}
</style>